.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

ul {
  padding: 0;
  list-style-type: none;
}

.deck-column {
  padding: 0.5rem;
}

.tooltip {
  border-bottom: 1px dotted #9f9f9f; /* If you want dots under the hoverable text */
  color: #eee;
}

.tooltip span, .tooltip title {
    display:none;
}
.tooltip:hover span:not(.click-disabled),.tooltip:active span:not(.click-disabled) {
    display:block;
    position:fixed;
    overflow:hidden;
    background-color: #FAEBD7;
    width:auto;
    z-index:9999;
    bottom:0;
    right:0;
}
/* This prevents tooltip images from automatically shrinking if they are near the window edge.*/
.tooltip span > img {
    max-width:none !important;
    overflow:hidden;
}